.bg-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-image: url('http://beddardroofing.co.uk/wp-content/uploads/2022/12/maintenance3_1500x600px.jpg')
}

.text-animate {
    position: relative;
    transition: opacity 0.5s ease-out;
    animation: moveSlideshow 12s linear infinite !important;
}